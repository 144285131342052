export const ACCOUNT_BLOCKED = "Account is blocked";
export const ACCOUNT_NOT_FOUND = "Account is not found";
export const ACCOUNT_INACTIVE = "Account is not active";
export const INCORRECT_CREDENTIAL = "Username and or password is incorrect";
export const INCORRECT_CODE = "Code is incorrect";
export const INCORRECT_CREDENTIAL_READONLY = "Wachtwoord is onjuist"
export const NO_MEMBERSHIP = "Account has no membership";
export const CODE_SENDING_ERROR = "Unable to generate or send the code";
export const UNKNOWN_ERROR = "Something is broken";
export const INVALID_SESSION_ERROR = "Session expired";
export const LIMIT_REACHED = "Not Accepted limit is reached";
export const NO_LAST_WISHES ="No last wishes data found";

export const registerError = (language,error, isCode) => {
    if(error === "Event object failed validation"){
        return language.INVALID_INPUT;
    }
    switch (error.statusCode) {
        case 400:
            if(localStorage.readOnlyMode){
                return language.READ_ONLY_ERROR_MESSAGE;
            }else{
                return language.BAD_REQUEST;
            }
            
        case 403:
            return language.ACCOUNT_BLOCKED;
        case 404:
            return language.ACCOUNT_NOT_FOUND;
        case 406:
            if (isCode) {
                return language.ERROR_GENERAL;
            } else {
                return language.RegisterErrorEmailExists;
            }
        case 422:
            return language.INCORRECT_CODE;
        case 424:
            return language.CODE_SENDING_ERROR;
        case 500:
            return language.UNKNOWN_ERROR;

        default:
            return language.UNKNOWN_ERROR;
    }
};

export const accountError = (language, error, isCode, isLogin) => {
    if(error === "Event object failed validation"){
        return language.INVALID_INPUT;
    }
    switch (error.statusCode) {
        case 400:
            if(localStorage.readOnlyMode){
                return language.READ_ONLY_ERROR_MESSAGE;
            }else{
                return language.BAD_REQUEST;
            }
        case 403:
            return language.ACCOUNT_BLOCKED;
        case 404:
            return language.ACCOUNT_NOT_FOUND;
        case 406:
            return language.ACCOUNT_INACTIVE;
        case 422:
            if (isLogin) {
                return language.INCORRECT_CREDENTIAL;
            } else {
                return language.INCORRECT_CREDENTIAL_READONLY;
            }
        case 424:
            if (isCode) {
                return language.INCORRECT_CODE;
            } else if (isLogin) {
                return language.NO_MEMBERSHIP;
            } else {
                return language.CODE_SENDING_ERROR;
            }
        case 500:
            return language.UNKNOWN_ERROR;
        default:
            return language.UNKNOWN_ERROR;
    }
};

// contact, important, media, message, events, membership, payments
export const handleError = (language, error, entity) => {
    if(error === "Event object failed validation"){
        return language.INVALID_INPUT;
    }
    switch (error.statusCode) {
        case 400:
            if(localStorage.readOnlyMode){
                return language.READ_ONLY_ERROR_MESSAGE;
            }else{
                return language.BAD_REQUEST;
            }
        case 403:
            return language.INVALID_SESSION_ERROR;
        case 404:
            return entity ? `${entity} ${language.NOT_FOUND}` : language.OBJECT_NOT_FOUND;
        case 406:
            return language.LIMIT_REACHED;
        case 422:
            return language.INCORRECT_CREDENTIAL_READONLY;
        case 500:
            return language.UNKNOWN_ERROR;
        default:
            return language.UNKNOWN_ERROR;
    }
};

export const lastWishesError = (language,error) => {
    if(error === "Event object failed validation"){
        return language.INVALID_INPUT;
    }
    switch (error.statusCode) {
        case 400:
            if(localStorage.readOnlyMode){
                return language.READ_ONLY_ERROR_MESSAGE;
            }else{
                return language.BAD_REQUEST;
            }
        case 403:
            return language.FORBIDDEN_ERROR;
        case 404:
            return language.NO_LAST_WISHES;
        case 422:
            return language.INCORRECT_CREDENTIAL_READONLY;
        case 406:
            return language.LIMIT_REACHED;
        case 500:
            return language.UNKNOWN_ERROR;
        default:
            return language.UNKNOWN_ERROR;
    }
};
