/*  eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
// eslint-disable jsx-a11y/alt-text
// eslint-disable no-useless-escape
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import React, { useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import { connect } from "react-redux";
import { Link, useHistory, withRouter, useParams } from "react-router-dom";
import { addAttribute, registerAccount, setLanguage } from "../../Actions/Form";
import cloud from "../../Images/cloud_PNG24.png";
import * as validator from "../../Utility/validations";
import {
    updateBrowserLaguage,
    PASSWORD_VALID_REGEX,
    validateField,
} from "../../Utility/Utility";
import {
    useMutationAPICallMutation,
    useGetCallQuery,
    accountApi,
} from "../../app/services/account";
import Footer from "../Common/Footer";

const Register = (props) => {
    const history = useHistory();
    const { language } = useParams();
    const [skip, setSkip] = React.useState(true);

    React.useEffect(() => {
        window.scrollTo(0, 0);
        updateBrowserLaguage(
            language ? language : localStorage.getItem("language"),
            props
        );
    }, []);

    const [registerData, setRegisterData] = React.useState({
        Email: {
            value: "",
            validations: { required: true, email: true },
            text: 'LOGIN_S2',
            valid: false,
        },
        Password: {
            value: "",
            validations: { required: true },
            text: 'LOGIN_S3',
            valid: false,
        },
        Firstname: {
            value: "",
            validations: { required: true },
            text: 'LOGIN_S7',
            valid: false,
        },
        Lastname: {
            value: "",
            validations: { required: true },
            text: 'LOGIN_S8',
            valid: false,
        },
        Phone: {
            value: "",
            validations: { required: true },
            text: 'LOGIN_S13',
            valid: false,
        },
        Country: {
            value: "NL",
            validations: { required: false },
            valid: true,
        },
        Agreement: {
            value: false,
            validations: { required: true },
            text: 'AGREEMENT_LABEL',
            valid: false,
        },
        code: {
            value: "",
        },
        countryISOCode: {
            value: "NL",
        },
    });
    const getRegister = useGetCallQuery(
        `account/register/code/${registerData.Email.value}`,
        {
            skip: skip,
        }
    );
    React.useEffect(() => {
        if (getRegister?.data?.statusCode === 201) {
            props.addAttribute({
                name: "isEmailValidation",
                value:
                    getRegister?.data?.registerState === "emailCodeSend"
                        ? true
                        : false,
            });
            props.addAttribute({
                name: "isSubmitCode",
                value: true,
            });
        }
    }, [getRegister.data]);

    const changeHandler = (event, code) => {
        let newData = JSON.parse(JSON.stringify(registerData));
        if(event?.target?.name === "Password") {
            onPasswordChange(event);
        }
        if (code) {
            newData[code].value = event;
        } else if (event?.currentTarget?.name === "Agreement") {
            newData[event.currentTarget.name].value =
                event.currentTarget.checked;
        } else {
            newData[event.currentTarget.name].value = event.currentTarget.value;
        }

        setRegisterData(newData);
        // validateField(newData);
    };

    const [updateAccount, { isLoading }] = useMutationAPICallMutation();
    const [isStrongPassword, setIsStrongPassword] = useState(false);

    const onPasswordChange = (e) => {
        if (PASSWORD_VALID_REGEX.test(e.target.value)) {
            setIsStrongPassword(true);
        } else {
            setIsStrongPassword(false);
        }
    };

    async function submit(newData) {
        let isFormValid = await validateField(newData, props.form.language);
        setRegisterData(isFormValid.formatData);
        let isValid = true;
        Object.keys(registerData).map((data) => {
            if (registerData[data].valid === false) {
                isValid = false;
            }
        });
        if (isValid) {
            if (!PASSWORD_VALID_REGEX.test(newData.Password.value)) {
                alert(props.form.language.STRONG_PASSWORD_ERROR);
            } else {
                registerForm(newData);
            }
            //props.register(history, registerData);
        } else {
            alert(isFormValid.validationMsg);
        }
    }

    const registerForm = async (newData) => {
        const languageISOCode =
            newData.Country.value === "US" ? "EN" : newData.Country.value;
        const body = {
            url: `account/register`,
            method: "POST",
            email: newData.Email.value,
            firstName: newData.Firstname.value,
            lastName: newData.Lastname.value,
            countryISOCode: newData.countryISOCode.value,
            password: newData.Password.value,
            languageISOCode: languageISOCode,
            phone: newData.Phone.value,
        };
        props.addAttribute({
            name: "registerData",
            value: newData,
        });
        await updateAccount(body)
            .unwrap()
            .then((response) => {
                handleSuccess("second");
            });
    };

    const handleSuccess = (response) => {
        if (response === "second") {
            //getRegister.refetch();
            //const result = dispatch(accountApi.endpoints.getCall.initiate(`account/register/code/${registerData.Email.value}`))
            // Removing the corresponding cache subscription
            //result.unsubscribe()
            setSkip(false);
        } else if (response === "forth") {
            getRegister.refetch();
        } else {
            alert(props.form.language.REGISTRATION_SUCCESS);
            history.push("login");
        }
    };

    const resetCode = (code) => {
        let newData = JSON.parse(JSON.stringify(registerData));
        setRegisterData({ ...newData, code: { value: "" } });
    };

    return (
        <React.Fragment>
            {(getRegister.isLoading || getRegister.isFetching || isLoading) && (
                <div className="overlay-momentio">
                    <CircularProgress
                        style={{ width: "100px", height: "100px" }}
                    />
                </div>
            )}
            <div className="fixed-header-container">
                <div className="header-container container">
                    <div className="header-title-section-login">
                        <div>
                            <a href={props.form.language.MOMENTIO_BASE_URL}>
                                {props.form.language.COMPANY_NAME}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="momentioHome register-form">
                <div
                    className="content-container container"
                    style={{ marginTop: "70px" }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            width: "inherit",
                            //position: "relative",
                        }}
                    >
                        <img className="momentio-cloud-bg" src={cloud} />
                        <div
                            className="mm-form-container"
                            style={{
                                paddingTop: "30px",
                                maxWidth: props.form.isSubmitCode
                                    ? "424px"
                                    : "",
                            }}
                        >
                            <div
                                className="mm-login-container"
                                style={{ padding: "32px" }}
                            >
                                <div className="mm-login-header">
                                    {!props.form.isSubmitCode
                                        ? props.form.language.LOGIN_S5
                                        : props.form.isEmailValidation
                                        ? props.form.language
                                              .REGISTER_EMAIL_HEADER
                                        : props.form.language
                                              .REGISTER_SMS_HEADER}
                                </div>
                                {!props.form.isSubmitCode && (
                                    <React.Fragment>
                                        <div className="display-flex">
                                            <div className="login-field-label login-field-label-height">
                                                {props.form.language.LOGIN_S6}
                                            </div>
                                            <div
                                                className="login-field-input"
                                                style={{
                                                    justifyContent: "left",
                                                }}
                                            >
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    style={{
                                                        marginRight: "15px",
                                                    }}
                                                    placeholder={
                                                        props.form.language
                                                            .LOGIN_S7
                                                    }
                                                    className="mm-field-input-sm"
                                                    name="Firstname"
                                                    value={
                                                        registerData.Firstname
                                                            .value
                                                    }
                                                    onChange={changeHandler}
                                                />
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder={
                                                        props.form.language
                                                            .LOGIN_S8
                                                    }
                                                    className="mm-field-input-sm"
                                                    name="Lastname"
                                                    value={
                                                        registerData.Lastname
                                                            .value
                                                    }
                                                    onChange={changeHandler}
                                                />
                                            </div>
                                        </div>
                                        <div className="display-flex">
                                            <div className="login-field-label login-field-label-height">
                                                {props.form.language.LOGIN_S2}
                                            </div>
                                            <div className="login-field-input">
                                                <input
                                                    type="email"
                                                    autoComplete="off"
                                                    placeholder={
                                                        props.form.language
                                                            .LOGIN_S2
                                                    }
                                                    name="Email"
                                                    value={
                                                        registerData.Email.value
                                                    }
                                                    onChange={changeHandler}
                                                />
                                            </div>
                                        </div>
                                        <div className="display-flex">
                                            <div className="login-field-label login-field-label-height">
                                                {props.form.language.LOGIN_S3}
                                            </div>
                                            <div className="login-field-input">
                                                <input
                                                    type="password"
                                                    autoComplete="off"
                                                    placeholder={
                                                        props.form.language
                                                            .LOGIN_S3
                                                    }
                                                    name="Password"
                                                    value={
                                                        registerData.Password
                                                            .value
                                                    }
                                                    onChange={changeHandler}
                                                />
                                            </div>
                                        </div>
                                        <div className="display-flex">
                                            <div className="login-field-label login-field-label-height">
                                                {props.form.language.LOGIN_S13}
                                            </div>
                                            <div className="login-field-input">
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder={
                                                        props.form.language
                                                            .LOGIN_S13
                                                    }
                                                    name="Phone"
                                                    value={
                                                        registerData.Phone.value
                                                    }
                                                    onChange={changeHandler}
                                                />
                                            </div>
                                        </div>
                                        <div className="display-flex">
                                            <div className="login-field-label login-field-label-height">
                                                {props.form.language.LOGIN_S9}
                                            </div>
                                            <div className="login-field-input">
                                                <ReactFlagsSelect
                                                    selected={
                                                        registerData.Country
                                                            .value
                                                    }
                                                    countries={["NL", "US"]}
                                                    customLabels={{
                                                        NL: "Netherlands",
                                                        US: "English",
                                                    }}
                                                    name="Country"
                                                    placeholder={
                                                        props.form.language
                                                            .LOGIN_S9
                                                    }
                                                    onSelect={(event) =>
                                                        changeHandler(
                                                            event,
                                                            "Country"
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="display-flex">
                                            <div className="login-field-label login-field-label-height">
                                                {props.form.language.LOGIN_S17}
                                            </div>
                                            <div className="login-field-input">
                                                <ReactFlagsSelect
                                                    selected={
                                                        registerData
                                                            .countryISOCode
                                                            .value
                                                    }
                                                    name="countryISOCode"
                                                    placeholder={
                                                        props.form.language
                                                            .LOGIN_S17
                                                    }
                                                    onSelect={(event) =>
                                                        changeHandler(
                                                            event,
                                                            "countryISOCode"
                                                        )
                                                    }
                                                    searchable={true}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="login-field-input login-field-label-height register-consent"
                                            style={{ height: "40px" }}
                                        >
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            registerData
                                                                .Agreement.value
                                                        }
                                                        style={{
                                                            paddingLeft: 0,
                                                        }}
                                                        name="Agreement"
                                                        value={
                                                            registerData
                                                                .Agreement.value
                                                        }
                                                        onChange={(event) =>
                                                            changeHandler(
                                                                event,
                                                                null
                                                            )
                                                        }
                                                    />
                                                }
                                                label={
                                                    <span>
                                                        {`${props.form.language.LOGIN_S10} `}
                                                        <a
                                                            href={`${props.form.language.MOMENTIO_BASE_URL}/gebruikersvoorwaarden`}
                                                            target="_blank"
                                                        >
                                                            {
                                                                props.form
                                                                    .language
                                                                    .LOGIN_S19
                                                            }
                                                        </a>
                                                    </span>
                                                }
                                            />
                                        </div>
                                        <div className="display-flex">
                                            <div className="login-field-label login-field-label-height password-error">
                                                {!isStrongPassword && <div>
                                                    {props.form.language.STRONG_PASSWORD_ERROR}
                                                </div>}
                                            </div>
                                            <div
                                                className="login-field-input mm-form-declaration"
                                                style={{
                                                    display: "block",
                                                    marginLeft: "0",
                                                }}
                                            >
                                                <span className="login-field-label">
                                                    {
                                                        props.form.language
                                                            .REGISTER_NOTE
                                                    }
                                                </span>{" "}
                                                <span className="login-field-input-text">
                                                    {
                                                        props.form.language
                                                            .LOGIN_S11
                                                    }
                                                </span>
                                            </div>
                                        </div>{" "}
                                    </React.Fragment>
                                )}
                                {props.form.isSubmitCode && (
                                    <div>
                                        <div
                                            className="momentio-text-normal login-field-label-height"
                                            style={{
                                                width: props.form.isSubmitCode
                                                    ? "100%"
                                                    : "",
                                                textAlign: props.form
                                                    .isSubmitCode
                                                    ? "left"
                                                    : "",
                                            }}
                                        >
                                            {props.form.isEmailValidation
                                                ? `${props.form.language.REGISTER_EMAIL_HELP} ${props.form.registerData?.Email.value}`
                                                : `${props.form.language.REGISTER_SMS_HELP} ${props.form.registerData?.Phone.value}`}
                                        </div>
                                        <div
                                            className="login-field-input"
                                            style={{
                                                width: props.form.isSubmitCode
                                                    ? "100%"
                                                    : "",
                                                textAlign: props.form
                                                    .isSubmitCode
                                                    ? "left"
                                                    : "",
                                            }}
                                        >
                                            <input
                                                type="text"
                                                placeholder={
                                                    props.form.language
                                                        .REGISTER_EMAIL_PLACEHOLDER
                                                }
                                                name="code"
                                                autoComplete="off"
                                                value={registerData.code.value}
                                                onChange={changeHandler}
                                            />
                                        </div>
                                    </div>
                                )}
                                <div
                                    className="mm-input-submit"
                                    style={{
                                        margin: props.form.isSubmitCode
                                            ? "0"
                                            : "",
                                        width: props.form.isSubmitCode
                                            ? "100%"
                                            : "",
                                    }}
                                >
                                    <button
                                        style={{
                                            textAlign: "center",
                                            width: "100%",
                                            marginTop: "24px",
                                        }}
                                        className="primary-button-blue"
                                        onClick={() => {
                                            if (props.form.isSubmitCode) {
                                                if (registerData.code) {
                                                    if (
                                                        props.form
                                                            .isEmailValidation
                                                    ) {
                                                        updateAccount({
                                                            url: `account/register/code`,
                                                            method: "POST",
                                                            email: props.form
                                                                .registerData
                                                                ?.Email.value,
                                                            code: registerData
                                                                .code.value,
                                                        })
                                                            .unwrap()
                                                            .then(
                                                                (response) => {
                                                                    handleSuccess(
                                                                        "forth"
                                                                    );
                                                                    resetCode();
                                                                }
                                                            );
                                                    } else {
                                                        updateAccount({
                                                            url: `account/register/code`,
                                                            method: "POST",
                                                            email: props.form
                                                                .registerData
                                                                ?.Email.value,
                                                            code: registerData
                                                                .code.value,
                                                        })
                                                            .unwrap()
                                                            .then(
                                                                (response) => {
                                                                    handleSuccess(
                                                                        "last"
                                                                    );
                                                                    resetCode();
                                                                }
                                                            );
                                                    }
                                                } else {
                                                    alert(
                                                        props.form.language.REGISTRATION_CODE_ERROR
                                                    );
                                                }
                                            } else {
                                                submit(registerData);
                                            }
                                        }}
                                    >
                                        {!props.form.isSubmitCode
                                            ? props.form.language.LOGIN_S5
                                            : props.form.isEmailValidation
                                            ? props.form.language
                                                  .REGISTER_EMAIL_BUTTON
                                            : props.form.language
                                                  .REGISTER_SMS_BUTTON}
                                    </button>
                                </div>
                            </div>
                            <div className="mm-form-links">
                                {!props.form.isSubmitCode && (
                                    <span className="momentio-blog-link">
                                        <Link to="/login">
                                            {props.form.language.LOGIN_S12}
                                        </Link>
                                    </span>
                                )}
                                {props.form.isSubmitCode && (
                                    <div className="momentio-blog-link">
                                        <div
                                            className="momentio-text-normal login-field-label-height"
                                            style={{
                                                width: "100%",
                                                textAlign: "center",
                                                textDecoration: "none",
                                            }}
                                        >
                                            {
                                                props.form.language
                                                    .REGISTER_CODE_RESEND
                                            }
                                        </div>
                                        {props.form.isEmailValidation ? (
                                            <span
                                                className="add-new-attachment"
                                                onClick={() => {
                                                    getRegister.refetch();
                                                    props.addAttribute({
                                                        name: "isSubmitCode",
                                                        value: true,
                                                    });
                                                }}
                                            >
                                                {
                                                    props.form.language
                                                        .REGISTER_EMAIL_RESEND
                                                }
                                            </span>
                                        ) : (
                                            <span
                                                className="add-new-attachment"
                                                onClick={() => {
                                                    getRegister.refetch();
                                                    props.addAttribute({
                                                        name: "isSubmitCode",
                                                        value: true,
                                                    });
                                                }}
                                            >
                                                {
                                                    props.form.language
                                                        .REGISTER_SMS_RESEND
                                                }
                                            </span>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { ...state };
};
const mapDispatchToProps = (dispatch) => {
    return {
        register: (
            history,
            payload,
            isRegistered,
            isSubmitCode,
            isSubmitCodeSMS
        ) =>
            dispatch(
                registerAccount(
                    history,
                    payload,
                    isRegistered,
                    isSubmitCode,
                    isSubmitCodeSMS
                )
            ),
        addAttribute: (payload) => dispatch(addAttribute(payload)),
        updateLanguage: (payload) => dispatch(setLanguage(payload)),
    };
};
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Register)
);
